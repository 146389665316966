import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import CONF from '../../../config'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divContainer: {
      marginTop: theme.spacing(3),
    },
    table: {
      // minWidth: 650,
      // tableLayout: 'fixed',
    },
    avatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    edit: {
      // top: theme.spacing(12),
      // left: theme.spacing(15),
      width: theme.spacing(40),
      height: theme.spacing(8),
      // backgroundColor: '#f77b18',
    },
    content: {
      width: '100%',
    },
    title: {
      fontSize: '48px',
      fontFamily: ['SourceSansPro', 'sans-serif'].join(','),
    },
    authorTypo: {
      fontSize: '14px',
      fontFamily: ['SourceSansPro', 'sans-serif'].join(','),
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(3),
    },
    breadcrumbs: {
      marginBottom: theme.spacing(3),
    },
    breadcrumbTypo: {
      fontSize: '16px',
      fontFamily: ['Cabin', 'sans-serif'].join(','),
      // fontWeight: 700
      // color: CONF.css.blue
      color: '#2C40D0',
      // marginBottom: theme.spacing(3),
    },
    editButton: {
      marginTop: theme.spacing(5),

      width: theme.spacing(20),
      height: theme.spacing(8),
    },
  })
)
