// @ts-nocheck
import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import { ROUTES } from '../constant'
import { Router } from '@reach/router'
import EducationDetail from '../components/EducationDetail'
import Education from '../components/Education'
import { useTranslation } from 'react-i18next'

export default () => {
  const { t, i18n } = useTranslation()
  return (
    <Layout>
      <SEO title={t('Investing Basics')} />
      <Router>
        <EducationDetail path={`${ROUTES.EDUCATION}/:slug`} />
        <Education path={`${ROUTES.EDUCATION}`} />
      </Router>
    </Layout>
  )
}
