import React from 'react'
import Grid from '@material-ui/core/Grid'
import SideTable from './SideTable'
import EducationContent from './EducationContent'
import { useStyles } from './style'
import { isScreenBiggerThan } from '../../utils/parse'
import SideTableMain from '../Education/SideTableMain'

export default function EducationDetail({ slug }) {
  const classes = useStyles()

  return isScreenBiggerThan('md') ? (
    <div className={classes.div}>
      <Grid container spacing={3}>
        {/*<Grid item xs={1} />*/}
        <Grid item xs={3} md={3}>
          <SideTableMain />
          {/*<SideTable slug={slug} />*/}
        </Grid>
        <Grid item xs={6} md={6}>
          <EducationContent slug={slug} />
        </Grid>
      </Grid>
    </div>
  ) : (
    <div className={classes.div}>
      <EducationContent slug={slug} />
    </div>
  )
}
