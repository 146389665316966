import React from 'react'
import { useMobileStyles, useStyles } from './style'
import { Typography } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import { navigate } from 'gatsby'
import { isScreenBiggerThan } from '../../../utils/parse'
import { EducationState } from '../state'
import { CATEGORY_LIST, ROUTES } from '../../../constant'
import { useTranslation } from 'react-i18next'

export default function SideTableMain() {
  const classes = useStyles()
  const mobileClasses = useMobileStyles()
  const { t, i18n } = useTranslation()
  // console.log(`====getKey('username')======${getKey('username')}`)
  return isScreenBiggerThan('md') ? (
    <div className={classes.paper}>
      <Typography
        className={classes.titleTypo}
        onClick={() => {
          EducationState.category.set('')
          navigate(ROUTES.EDUCATION)
        }}
      >
        {t('All')}
      </Typography>

      {CATEGORY_LIST.map((record) => {
        return (
          <div key={record}>
            <Divider key={`${record}Divider`} />
            <Typography
              className={classes.listTypo}
              key={`${record}Typography`}
              onClick={() => {
                EducationState.category.set(record)
                navigate(ROUTES.EDUCATION)
              }}
            >
              {t(record)}
            </Typography>
          </div>
        )
      })}

      <Divider />
    </div>
  ) : (
    <div className={mobileClasses.paper}>
      <Typography
        className={mobileClasses.titleTypo}
        onClick={() => {
          EducationState.category.set('')
          navigate(ROUTES.EDUCATION)
        }}
      >
        {t('All')}
      </Typography>

      {CATEGORY_LIST.map((record) => {
        return (
          <div key={record}>
            <Divider key={`${record}Divider2`} />
            <Typography
              className={mobileClasses.listTypo}
              key={`${record}Typography2`}
              onClick={() => {
                EducationState.category.set(record)
                navigate(ROUTES.EDUCATION)
              }}
            >
              {t(record)}
            </Typography>
          </div>
        )
      })}
    </div>
  )
}
