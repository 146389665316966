import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    div: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(20),
    },
  })
)
