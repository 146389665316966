import React, { useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { DATE_DISPLAY_FORMAT } from '../../../constant'
import { useStyles } from './style'
import { EducationAction, EducationState } from '../../Education/state'
import { useState } from '@hookstate/core'
import { formatDateToDisplay } from '../../../utils/parse'

export default function EducationContent({ slug }) {
  const classes = useStyles()
  const educationDetail = useState(EducationState.educationDetail)

  useEffect(() => {
    EducationAction.loadEducationDetail(slug)()
  }, [])

  // console.log(`======post.data?.main_points?========${educationDetail?.main_points.get()}`)

  const author = educationDetail.author?.ornull?.username.get()
  return (
    <div className={classes.divContainer}>
      {/*<Breadcrumbs*/}
      {/*  className={classes.breadcrumbs}*/}
      {/*  separator={<NavigateNextIcon fontSize="small" />}*/}
      {/*  aria-label="breadcrumb"*/}
      {/*>*/}
      {/*  <Typography className={classes.breadcrumbTypo}>TECHNICAL ANALYSIS</Typography>*/}
      {/*  <Typography className={classes.breadcrumbTypo}>TECHNICAL ANALYSIS BASIC EDUCATION</Typography>*/}
      {/*</Breadcrumbs>*/}

      <Typography className={classes.title} variant="h4" component="h2" gutterBottom>
        {educationDetail.title.get()}
      </Typography>

      {/*<Divider />*/}
      {author != null && (
        <Typography className={classes.authorTypo} variant="h4" component="h2" gutterBottom>
          {`By ${educationDetail.author?.ornull?.username.get()} | Updated ${formatDateToDisplay(
            educationDetail.created_at.get(),
            DATE_DISPLAY_FORMAT
          )}`}
        </Typography>
      )}

      <div className={classes.content} dangerouslySetInnerHTML={{ __html: educationDetail?.content.get() as string }} />

      {/*<DiscussionEmbed*/}
      {/*  shortname={CONF.disqus.shortName}*/}
      {/*  config={{*/}
      {/*    url: `${CONF.disqus.baseUrl}${window.location.pathname}`,*/}
      {/*    identifier: `${educationDetail.id.get()}`,*/}
      {/*    title: educationDetail.title.get(),*/}
      {/*    // language: 'zh_TW', //e.g. for Traditional Chinese (Taiwan)*/}
      {/*  }}*/}
      {/*/>*/}
    </div>
  )
}
