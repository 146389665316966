import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(3),
    },
    table: {
      // width: '100%',
      // minWidth: 650,
      // tableLayout: 'fixed',
    },
    pagination: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
    titleTypo: {
      marginLeft: theme.spacing(2),
      cursor: 'pointer',
    },
  })
)
