import React from 'react'
import Grid from '@material-ui/core/Grid'
import { useStyles } from './style'
import BoringList from './BoringList'
import SideTableMain from './SideTableMain'

export default function Education() {
  const classes = useStyles()

  return (
    <div className={classes.div}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          {/*<TableOfContentEducation />*/}
          <SideTableMain />
        </Grid>
        <Grid item xs={12} md={9}>
          {/*<EducationTable />*/}
          <BoringList />
        </Grid>
      </Grid>
    </div>
  )
}
